import React from 'react';
import convert from 'htmr';
import { animateScroll as scroll } from 'react-scroll';

class EpcFAQ extends React.Component {
  state = {
    isActiveId: ''
  };

  scrollToTop = () => {
    scroll.scrollToTop();
  };

  addActiveClass = (e, id) => {
    e.preventDefault();
    this.setState({
      isActiveId: id
    });
  };

  render() {
    const { faqs } = this.props;

    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="faq__list">
            {faqs.map((faq) => {
              return (
                <div className="faq__item" key={faq.id}>
                  <button
                    className="question"
                    id={faq.id}
                    onClick={(e, id) => this.addActiveClass(e, faq.id)}
                    onKeyDown={(e, id) => this.addActiveClass(e, faq.id)}
                    rel="noopener noreferrer"
                  >
                    {faq.question}
                  </button>
                  <div className={`answer ${this.state.isActiveId === faq.id ? 'active' : ''}`}>
                    {convert(faq.answer)}
                  </div>
                </div>
              );
            })}

          </div>
        </div>
      </div>
    );
  }
}

export default EpcFAQ;
