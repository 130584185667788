import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import FAQ from '../components/faq/epc-faq';

import faqs from '../components/faq/epc-faqs';

const EpcFAQPage = () => {
  return (
    <Layout>
      <SEO title="BMW Performance Driving School | Epc Program FAQ" description="Frequently asked questions for new BMW owners who qualify for the BMW Performance Center Delivery Program and are ready to fully experience BMW culture!" />
      <div className="faq-page epcfaq">
        <section className="hero__header">
          <div className="container">
          </div>
        </section>
        <section className="epcfaq">
          <div className="container">
          <h4>3RD PARTY EVENT PLANNER COMMISSION PROGRAM - FREQUENTLY ASKED QUESTIONS</h4>
          <br />
            <FAQ faqs={faqs} />
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default EpcFAQPage;
